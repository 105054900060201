<template>
  <div id="press">
    <Navbar class="dark-menu-icon" />
    <div id="blog-index">
      <h1 class="page-title">HEADLINES</h1>

      <ul>
        <li
          v-for="blogPost in blogPosts"
          :key="blogPost.id"
          @click="readPost(blogPost)"
        >
          <h2>{{ blogPost.title }}</h2>
          <p>{{ blogPost.subtitle }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  name: "BlogIndex",
  components: {
    Navbar
  },
  metaInfo: function() {
    return {
      title: " - Headlines",
      meta: [
        {
          name: "description",
          content: "Harry Fox headlines"
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  computed: {
    blogPosts: function() {
      return this.$store.state.blogPosts.posts;
    }
  },
  methods: {
    readPost: function(post) {
      if (post.link) {
        window.open(post.link, "_blank");
      } else {
        this.$router.push({ name: "blog", params: { id: post.id } });
      }
    }
  }
};
</script>

<style lang="scss">
#blog-index {
  h1 {
    margin-bottom: 50px;
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      border-bottom: solid 0.5px #d8d8d8;
      padding-bottom: 20px;
      margin-bottom: 20px;
      cursor: pointer;

      &:last-child {
        border: 0;
      }

      h2 {
        font-family: "Abel", sans-serif;
        font-weight: 900;
        font-size: 1.188em;
        text-transform: uppercase;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}
</style>
